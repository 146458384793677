.home {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  flex-direction: column;
}

.voltar {
  display: flex;
  width: 75%;
  margin-bottom: 20px;
}
