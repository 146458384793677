.button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #cc0000;
  border: none;
  width: 300px;
  height: 50px;
  color: white;
  border-radius: 10px;
  cursor: pointer;
}
