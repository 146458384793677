.parallaxEffect {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;

  transform-style: preserve-3d;

  .innerElement {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.card {
  display: flex;
  flex-direction: column;
  width: 438px;
  height: 680px;
  position: relative;
  align-items: center;
  border-radius: 5px;

  color: #fff;

  background: url("../../assets/smoke.png") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.card::before {
  content: "";
  position: absolute;
  inset: -5px;
  z-index: -1;
  border-radius: 10px;
  background: url("../../assets/holograpich.png") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.cardOpacity {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.name {
  display: flex;
  margin-bottom: 20px;
  background-color: #fff;
  width: 90%;
  color: #000;
  border-radius: 10px;
  padding: 5px;
  justify-content: space-between;
}

.pokemonImage {
  display: flex;
  width: 45%;
  height: 30%;
  align-items: center;
  justify-content: center;

  border-radius: 50%;

  background: url("../../assets/holograpich.png") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  img {
    width: 100%;
    height: 100%;
    z-index: 999;
  }
}

.id {
  display: flex;
  color: rgb(0, 0, 0);
  z-index: 0;
  position: fixed;
  width: 100%;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: 100px;
}

.idText {
  background: transparent;
  -webkit-text-fill-color: transparent;
  background-clip: text;

  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #ffffff88;
}

.cardInfo {
  display: flex;
  background: #fff;
  justify-content: space-between;
  flex-direction: column;
  width: 90%;
  border-radius: 10px;
  align-items: center;
  margin-top: 12px;

  color: #000;
}

.cardVerso {
  transform: rotateY(180deg);
  width: 448px;
  height: 676px;
  border-radius: 10px;
}

.containerWidthHeight {
  display: flex;
  flex-direction: row;
  width: 70%;
  align-items: center;
  justify-content: space-around;
}

.wrapperHabilidades {
  display: flex;
  width: 90%;
  flex-direction: column;
}

.containerHabilidades {
  display: flex;
  justify-content: space-between;
}

.habilidade {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #d9d9d9;
  border-radius: 10px;

  min-width: 100px;
  font-size: 14px;
}

.containerStats {
  display: flex;
  width: 90%;
  flex-direction: column;
}

.statsContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.statsParagraph {
  width: 140px;
}

.line {
  width: 40%;
}

[data-element="bug"] {
  background: var(--bug);
}

[data-element="water"] {
  background: var(--water);
}

[data-element="grass"] {
  background: var(--grass);
}

[data-element="fire"] {
  background: var(--fire);
}

[data-element="normal"] {
  background: var(--normal);
}

[data-element="poison"] {
  background: var(--poison);
}

[data-element="electric"] {
  background: var(--electric);
}

[data-element="ground"] {
  background: var(--ground);
}

[data-element="flying"] {
  background: var(--flying);
}

[data-element="fighting"] {
  background: var(--fighting);
}

[data-element="fairy"] {
  background: var(--fairy);
}

[data-element="psychic"] {
  background: var(--psychic);
}

[data-element="ghost"] {
  background: var(--ghost);
}

[data-element="rock"] {
  background: var(--rock);
}

[data-element="ice"] {
  background: var(--ice);
}

[data-element="dark"] {
  background: var(--dark);
}

[data-element="steel"] {
  background: var(--steel);
}

[data-element="dragon"] {
  background: var(--dragon);
}

[data-element="unknown"] {
  background: var(--unknown);
}

[data-element="undefined"] {
  background: var(--undefined);
}

[data-element="shadow"] {
  background: var(--shadow);
}
