.home {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 50px;
}

.pokemonList {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 16px;
  margin-bottom: 24px;
  padding: 5px;
}

.containerButton {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.pokebola {
  width: 50px;
  height: 50px;
}

@media screen and (min-width: 839px) {
  .pokemonList {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
    width: 100%;
    margin-bottom: 24px;
    padding: 5px;
  }
}
